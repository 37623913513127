<div class="top-bar" id="top_bar">
    <div class="top-menu-controls">
        <!--    <button class="btn btn-dark btn-rounded pull-left" (click)="goBack()" style="margin-top: 5px; margin-left: 15px;">-->
        <!--      <i class="icon-feather-chevron-left"></i>-->
        <!--      <span class="visible-md-inline-block visible-lg-inline-block hidden-mobile"> Go Back</span>-->
        <!--    </button>-->
        <a href="javascript:void(0)" (click)="goBack()" style="font-size: 18px;margin-top: 5px; margin-left: 15px;" class="buttonBackOnHeader pull-left" *ngIf="canShowGoBack()">
            <i class="icon-feather-chevron-left"></i>
            <span class="visible-md-inline-block visible-lg-inline-block hidden-mobile"> Go Back</span>
        </a>

        <a href="javascript:void(0)" *ngIf="userService.isAdmin() || userService.isSuperAdmin()" title="Follow Up" (click)="showAddScheduleModal(scheduleModal)" style="color: #1071ef;margin: 10px;" class="buttonBackOnHeader follorUp pull-right">
            <i class="fa fa-calendar" style="font-size: 25px;"></i>
        </a>

        <div class="top-icon top-settings os-dropdown-trigger os-dropdown-position-left pull-right">
            <!--<i class="picons-thin-icon-thin-0699_user_profile_avatar_man_male"></i>-->
            <img class="np_image2" src="assets/img/np-account-1181419-000000.svg">
            <i class="os-icon os-icon-chevron-down"></i>
            <div class="os-dropdown">
                <div class="icon-w">
                    <i class="os-icon os-icon-ui-46"></i>
                </div>
                <ul>
                    <li>
                                <!-- <a href="/account-settings" *ngIf="userService.isMerchant() && !userService.isDwollaSuspended()">
                                     <i class="os-icon os-icon-ui-49"></i><span>Profile Settings</span>
                                   </a> -->
                        <a routerLink="/admin-account/details" *ngIf="userService.isAdmin() || userService.isSuperAdmin()">
                            <i class="os-icon os-icon-ui-49"></i><span>Profile Settings</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/account/billing" *ngIf="userService.isMerchant() && !userService.isDwollaSuspended()">
                            <i class="os-icon os-icon-grid-10"></i><span>Billing</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="logout()"><i class="os-icon os-icon-signs-11"></i><span>Logout</span></a>
                    </li>
                </ul>
            </div>
        </div>

        <div *ngIf="!userService.isDwollaSuspended()" class="messages-notifications os-dropdown-trigger os-dropdown-position-left pull-right">
            <img [ngClass]="{ 'jingleBell': intCountMessages > 0 }" class="np_image2" src="assets/img/np-notification-1155784-000000.svg">
            <!--<i class="icon-feather-bell"></i>-->
            <!--<i class="os-icon os-icon-others-43"></i>-->
            <div class="new-messages-count" *ngIf="intCountMessages > 0">
                {{intCountMessages}}
            </div>
            <div class="os-dropdown light message-list" style="right: -30px;">
                <ul *ngIf="listMessages.length">
                    <li *ngFor="let objMessage of listMessages">
                        <a *ngIf="objMessage.status == 'requestPayments'" (click)="openModal(getNewRequestPayment)">
                            <!--              <div class="user-avatar-w">
                              <img alt="" src="assets/img/avatars/user2-160x160.jpg">
                            </div>-->
                            <div class="message-content">
                                <h6 class="message-from">{{objMessage.message1}}</h6>
                                <h6 class="message-title">{{objMessage.message2}}</h6>
                            </div>
                        </a>
                        <a *ngIf="objMessage.status == 'acceptChecks'" (click)="openModalAC(newAcceptChecks)">
                            <div class="message-content">
                                <h6 class="message-from">{{objMessage.message1}}</h6>
                                <h6 class="message-title">{{objMessage.message2}}</h6>
                            </div>
                        </a>
                    </li>
                </ul>
                <ul *ngIf="!listMessages.length">
                    <li>
                        <a href="javascript:void(0)">
                            <div class="message-content">
                                <h6 class="message-from">List messages empty</h6>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <button class="btn btn-primary bg-yellow backToAdmin btn-rounded pull-right" (click)="userService.backToSuperAdmin()" *ngIf="userService.isGhostLogin" style="margin-top: 5px; margin-left: 10px;" type="button">
      <i class="fa fa-arrow-circle-o-left"></i>
      <span class="visible-md-inline-block visible-lg-inline-block"> Back to Admin</span>
    </button>
        <!--------------------
    END - Messages Link in secondary top menu
    -------------------->
        <!--------------------
    START - Settings Link in secondary top menu
    -------------------->

    </div>
</div>

<ng-template #getNewRequestPayment let-c="close" let-d="dismiss" let-create="editContactInfo">
    <div class="modal-blue-bg onboarding-content">
        <div class="modal-header">
            <!--<h4 class="modal-title">Update Email</h4>-->
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <app-client-invoices [isLightVersion]="lightVersion"></app-client-invoices>
        </div>
        <div class="modal-footer">
            <button class="slick-prev slick-arrow" type="button" (click)="c('Close click')">Cancel</button>
        </div>
    </div>
</ng-template>

<ng-template #newAcceptChecks let-c="close" let-d="dismiss" let-create="editContactInfo">
    <div class="modal-blue-bg onboarding-content">
        <div class="modal-header">
            <!--<h4 class="modal-title">Update Email</h4>-->
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <app-accept-checks [isLightVersionAC]="LightVersionAC"></app-accept-checks>
        </div>
        <div class="modal-footer">
            <button class="slick-prev slick-arrow" type="button" (click)="c('Close click')">Cancel</button>
        </div>
    </div>
</ng-template>

<ng-template #scheduleModal let-c="close" let-d="dismiss" let-create="editContactInfo">
    <div class="modal-blue-bg onboarding-content">
        <div class="modal-header">
            <!--<h4 class="modal-title">Update Email</h4>-->
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <div id="mycalendar"></div>

            <div *ngIf="addNewEvent">
                <form name="form" (ngSubmit)="f.form.valid && createNewEvent()" #f="ngForm">
                    <br>
                    <div *ngIf="addNewEvent" class="row">
                        <h3 class="col-lg-12" style="margin-top: 5px;margin-bottom: 2em;">
                            Create Event For {{dateEventFormated}}
                            <a href="javascript:void(0)" class="pull-right" (click)="clearEvent()" style="position: relative;top: 0px;right: 0px;">
                                <i class="picons-thin-icon-thin-0153_delete_exit_remove_close" aria-hidden="true"></i>
                            </a>
                        </h3>
                        <div class="col-lg-4 col-md-5 col-sm-11 col-xs-11 margin select-merchant-transaction pull-left">
                            <ng-select class="custom" [items]="usersList" bindLabel="name" placeholder="Select Merchant" bindValue="u_token" name="merchant" required [searchable]="true" [(ngModel)]="newEventObj.merchant" (change)="onChangeEvent($event)" #merchant="ngModel">
                                <ng-template ng-option-tmp let-item="item">
                                    {{item.name}}
                                </ng-template>
                            </ng-select>
                            <div class="help-block form-text text-muted form-control-feedback" *ngIf="!merchant.valid && (merchant.dirty || merchant.touched)">
                                <span *ngIf="merchant.errors.required">
                   Merchant is required
                  </span>
                            </div>
                            <label style="margin-top: 10px;margin-left: 6px;margin-bottom: 0px;"></label>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-6 col-xs-5 margin pull-left">
                            <ngb-timepicker [meridian]="true" class="timePeeckerBox" [(ngModel)]="newEventObj.time" name="time" #time="ngModel" required></ngb-timepicker>
                            <div class="help-block form-text text-muted form-control-feedback" *ngIf="!time.valid && (time.dirty || time.touched)">
                                <span *ngIf="time.errors.required">
                   Time is required
                  </span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-5 col-sm-6 col-xs-6 margin pull-left">
                            <input type="text" class="form-control" placeholder="Event Name" [(ngModel)]="newEventObj.eventText" name="eventText" required #eventText="ngModel">
                            <div class="help-block form-text text-muted form-control-feedback" *ngIf="!eventText.valid && (eventText.dirty || eventText.touched)">
                                <span *ngIf="eventText.errors.required">
                   Event Name is required
                  </span>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-4 col-xs-11 margin pull-left">
                            <input id="color_input" class="form-control" type="text" [(colorPicker)]="newEventObj.color" [value]="newEventObj.color" [style.background]="newEventObj.color" [cpOKButton]='true'>
                        </div>
                        <div class="col-lg-9 col-md-8 col-sm-7 col-xs-11 margin pull-left">
                            <input type="text" class="form-control" placeholder="Description ( Optional )" [(ngModel)]="newEventObj.description" name="description" #description="ngModel">
                        </div>
                        <br>
                        <div class="row clear" style="margin-top: 5em;" *ngIf="addNewEvent">
                            <button class="slick-prev slick-arrow" (click)="c('Close click')">Cancel</button>
                            <button class="slick-next slick-arrow" type="submit" [disabled]="!f.form.valid">Add Event</button>
                        </div>

                    </div>
                    <br>
                </form>
            </div>

            <div *ngIf="editEvent">
                <form name="form" (ngSubmit)="f.form.valid && editEventSh()" #f="ngForm">
                    <br>
                    <div *ngIf="editEvent" class="row">
                        <h3 class="col-lg-12" style="margin-top: 5px;margin-bottom: 2em;">
                            Edit Event For {{dateEventFormated}}
                            <a href="javascript:void(0)" class="pull-right" (click)="clearEvent()" style="position: relative;top: 0px;right: 0px;">
                                <i class="picons-thin-icon-thin-0153_delete_exit_remove_close" aria-hidden="true"></i>
                            </a>
                        </h3>
                        <div class="col-lg-4 col-md-5 col-sm-11 col-xs-11 margin select-merchant-transaction pull-left">
                            Merchant - {{newEventObj.merchant}}
                            <label style="margin-top: 10px;margin-left: 6px;margin-bottom: 0px;"></label>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-6 col-xs-5 margin pull-left">
                            <ngb-timepicker [meridian]="true" class="timePeeckerBox" [(ngModel)]="newEventObj.time" name="time" #time="ngModel" required></ngb-timepicker>
                            <div class="help-block form-text text-muted form-control-feedback" *ngIf="!time.valid && (time.dirty || time.touched)">
                                <span *ngIf="time.errors.required">
                   Time is required
                  </span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-5 col-sm-6 col-xs-6 margin pull-left">
                            <input type="text" class="form-control" placeholder="Event Name" [(ngModel)]="newEventObj.eventText" name="eventText" required #eventText="ngModel">
                            <div class="help-block form-text text-muted form-control-feedback" *ngIf="!eventText.valid && (eventText.dirty || eventText.touched)">
                                <span *ngIf="eventText.errors.required">
                   Event Name is required
                  </span>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-4 col-xs-11 margin pull-left">
                            <input id="color_input" class="form-control" type="text" [(colorPicker)]="newEventObj.color" [value]="newEventObj.color" [style.background]="newEventObj.color" [cpOKButton]='true'>
                        </div>
                        <div class="col-lg-9 col-md-8 col-sm-7 col-xs-11 margin pull-left">
                            <input type="text" class="form-control" placeholder="Description ( Optional )" [(ngModel)]="newEventObj.description" name="description" #description="ngModel">
                        </div>
                        <br>
                        <div class="row clear" style="margin-top: 5em;" *ngIf="editEvent">
                            <a style="color: #fff;" class="btn btn-danger btn-rounded ng-star-inserted" (click)=deleteEvent(newEventObj)>
                                <i _ngcontent-c24="" class="picons-thin-icon-thin-0056_bin_trash_recycle_delete_garbage_empty"></i> Delete Event</a>
                            <button class="slick-next slick-arrow" type="submit" [disabled]="!f.form.valid">Edit</button>
                        </div>

                    </div>
                    <br>
                </form>
            </div>
        </div>


    </div>
</ng-template>
